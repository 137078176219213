.landing {
  min-height:500px;
  display:flex;
  justify-content: center;
  align-items: center;
  .presentation-titre {
    font-size:50px;
    margin:30px;
  }
}
