html, body, #root {
  user-select: none;
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
}
html {
    scroll-behavior: smooth;
}
@font-face {
    font-family: "vinz";
    src: url("fonts/AkkuratMonoStdVINCENT.ttf") format("truetype");
}
body {background:#000;height:100%;font-family: "vinz",arial,sans-serif;font-size:16px;}
#root {
  a {color:#666;}
  a:hover, a:active, a:visited, a:focus {color:#FFF;text-decoration:none;outline:none;}
  a div {color:#000;}
  a:hover div {color:#000;}
  a:active div {color:#000;}
  .pad0 {padding:0;}
  .padv0 {padding-top:0;}
  .pad30 {padding:0 30px;}
  .padt30 {padding:30px;}
  .padv15 {padding-top:15px;padding-bottom:15px;}
  .ratio1 {
  	position: relative;
  	padding-bottom: 31%;
  	height: 0;
  }
  .ratioimg {
  	position: relative;
  	padding-bottom: 74.5%;
  	height: 0;
  }
  .ratio11 {
  	position: relative;
  	padding-bottom: 50%;
  	height: 0;
  }
  .ratio2 {
  	position: relative;
  	padding-bottom: 42%;
  	height: 0;
  }
  .ratio21 {
  	position: relative;
  	padding-bottom: 64%;
  	height: 0;
  }
  .fullscreen {
      position:absolute;
  }
  .ratio3 {
  	position: relative;
  	padding-bottom: 129%;
  	height: 0;
  	overflow:hidden;
  }
  .ratio31 {
  	position: relative;
  	padding-bottom: 40%;
  	height: 0;
  }
  .inter {height:30px;}
  .logo img {position:absolute;opacity:0.9;}
  @keyframes rot1 {
  	from {
  		transform: rotate(1deg)
  		           translate(.6%)
  		           rotate(0deg);
  	}
  	to {
  		transform: rotate(361deg)
  		           translate(.6%)
  		           rotate(-360deg);
  	}
  }
  @keyframes rot2 {
  	from {
  		transform: rotate(1deg)
  		           translate(.6%)
  		           rotate(0deg);
  	}
  	to {
  		transform: rotate(361deg)
  		           translate(.6%)
  		           rotate(-360deg);
  	}
  }
  @keyframes rot3 {
  	from {
  		transform: rotate(1deg)
  		           translate(.6%)
  		           rotate(0deg);
  	}
  	to {
  		transform: rotate(-359deg)
  		           translate(.6%)
  		           rotate(360deg);
  	}
  }

  .logo img:nth-child(1) {
  	bottom:15%;left:0;
  	animation: rot1 20s infinite linear;
  }
  .logo img:nth-child(2) {
  	bottom:6%;left:2%;
  	animation: rot2 35s infinite linear;
  }
  .logo img:nth-child(3) {
  	bottom:0%;left:1.5%;
  	animation: rot3 28s infinite linear;
  }
  .strech {
  	position:absolute;
  	top:0;
  	left:0;
  	bottom:0;
  	right:0;
  }
  .eveimage {
  	position:relative;
  	left:-1px;
  }

  .highlight {
  	background-color:#fff;
  }

  .logo {position:relative;}


  div.menu {text-align:right;position:relative;}
  div.menu.visible-xs {text-align:right;position:relative;margin-bottom:30px;}
  div.logo div {width:100%;}

  span.menu {font-size:1em;color:#fff;padding:10px 10px 5px 10px;}
  span.fb, span.ig {
  	bottom: -0.25em;
  	display: inline-block;
  	position: relative;
  width: 1.5em;
  }
  .img-responsive {width:100.5%;max-width:100.5%;}
  .swiper-pagination-bullet-active {
      background: #ccc none repeat scroll 0 0;
  }
  .swiper-pagination-bullet {
      background: #aaa none repeat scroll 0 0;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
      bottom: 0;
  }
  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  	background-image: url(components/assets/droite-xs.svg);
  	background-size: auto;
  	width:50px;
  	height:32px;
  }
  .swiper-button-next::after, .swiper-container-rtl .swiper-button-prev::after {
    content:'';
  }
  .fullscreen-button {
  	background-image: url(components/assets/fullscreen.svg);
  	background-size: auto;
  	width:53px;
  	height:25px;
  	position:absolute;
  	top:15px;
  	right:15px;
  	z-index:1;
  	cursor:pointer;
  }
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  	background-image: url(components/assets/gauche-xs.svg);
  	background-size: auto;
  	width:50px;
  	height:32px;
  }
  .swiper-button-prev::after, .swiper-container-rtl .swiper-button-next::after {
    content:'';
  }
  .swiper-button-next.evt, .swiper-container-rtl .swiper-button-prev.evt {
  	right: -25px;
  }
  .swiper-button-prev.evt, .swiper-container-rtl .swiper-button-next.evt {
    left: -25px;
  }
  .swiper-button-next {right:1px;}
  .swiper-button-prev {left:1px;}
  span.menu {border:2px solid #000;}
  .menu .on {border:2px solid #FFF;}

  .upper {text-transform: uppercase;}
  .temps {text-align:right;}

  .hidden-xs .evediv {padding:1.25vw 1.875vw;}
  .hidden-xs .evetype {font-size:1vw;}
  .hidden-xs .evetitre {font-size:1.875vw;line-height:1.875vw;}
  .hidden-xs .evedate {font-size:1.375vw;}
  .hidden-xs .evelieu {font-size:1vw;}
  .hidden-xs .evediv p {margin:0;}

  .visible-xs .evediv {padding:4.5vw 6.75vw;}
  .visible-xs .evetype {font-size:3vw;}
  .visible-xs .evetitre {font-size:6.5vw;line-height:6.5vw;}
  .visible-xs .evedate {font-size:4.95vw;}
  .visible-xs .evelieu {font-size:3.6vw;}
  .visible-xs .evediv p {margin: 0;}

  .visible-xs .p-evediv {padding:4.5vw 6.75vw;}
  .visible-xs .p-evetype {font-size:3.6vw;}
  .visible-xs .p-eveartiste {font-size:3.2vw;}
  .visible-xs .p-evetitre {font-size:6.75vw;line-height:6.75vw;}
  .visible-xs .p-evedate {font-size:4.95vw;}
  .visible-xs .p-evelieu {font-size:3.6vw;}
  .visible-xs .p-evetexte {font-size:0.8em;}
  .visible-xs .p-evediv p {margin: 0;}
  .visible-xs .p-evetexte p{margin-top:0.8em;}
  .visible-xs .p-retour {margin-top:30px;}
  .visible-xs .evemask {position:absolute;width:33.33%;top:0;left:0;bottom:0;}

  .hidden-xs .p-evediv {padding:1.8vw 1.5vw;}
  .hidden-xs .p-evetype {font-size:1.2vw;}
  .hidden-xs .p-eveartiste {font-size:1.1vw;}
  .hidden-xs .p-evetitre {font-size:2vw;line-height:2vw;}
  .hidden-xs .p-evedate {font-size:1.5vw;}
  .hidden-xs .p-evelieu {font-size:1.3vw;}
  .hidden-xs .p-evetexte {font-size:0.8em;}
  .hidden-xs .p-evediv p {margin: 0;}
  .hidden-xs .p-evetexte p{margin-top:1vw;}
  .hidden-xs .p-retour {margin-top:30px;}
  .hidden-xs .evemask {position:absolute;width:33.33%;top:0;left:0;bottom:0;}
  .hidden-xs .evepsmask {position:absolute;width:66.67%;top:0;right:0;bottom:0;background-color:#ccc;}
  .hidden-xs .artpsmask {position:absolute;width:66.67%;top:0;right:0;bottom:0;background-color:#fff;}

  .visible-xs .p-artdiv {padding:4.5vw 6.75vw;background-color:#CCC;}
  .visible-xs .p-arttitre {font-size:6.75vw;line-height:6.75vw;}
  .visible-xs .p-arttexte {font-size:0.8em;line-height:1em;padding-top:15px;}
  .visible-xs .ps {font-size:0.8em;line-height:1em;padding-top:15px;}

  .hidden-xs .p-artdiv {padding:1.8vw 1.5vw;background-color:#CCC;}
  .hidden-xs .p-arttitre {font-size:2vw;line-height:2vw;}
  .hidden-xs .p-arttexte {font-size:1em;line-height:1em;padding-top:15px;}

  .footer {background-color:#CCC;padding:15px;}
  .footer h3 {padding:0 15px;}
  .items {background-color:#efefef;padding:15px;}
  .edito {background-color:#efefef;padding:15px;}
  .edito h3 {padding:0 15px;}
  .p-artliste {background-color:#CCC;padding:2em;}
  .p-artliste h3 {font-size:0.8em;line-height:1em;background-color:#CCC;margin:0 0 8px 0;}
  p.partenaire {font-size:0.9em;text-decoration:underline;}
  .partenaires, .credits {padding-top:20px;}
  .credits {font-size:0.8em;}
  .header, .diap {padding:15px 15px;}
  .evecontainer {margin-bottom:30px;}
  .cn {text-align:left;color:#fff;font-size:1em;}
  .adr {text-align:right;color:#fff;font-size:0.8em;margin-bottom:50px;}
  .ps {padding:30px;}
  .eveps {color:#000;}
  .relative {position:relative;}
  .cols-padding {padding:15px;}
  .edito, .items, .footer {font-size:0.8em;}
  .justif {text-align:justify;}
  .items div {font-size:1em;}
  .menu>div {
      line-height:3em;
  }
  .menu.visible-xs {
      font-size:0.8em;
  }
  .visible-xs .cn, .visible-xs .adr {
      font-size:0.7em;
  }
  @media (min-width: 992px) {
  	.hidden-xs .evediv {padding:1vw 1.5vw;}
  	.hidden-xs .evetype {font-size:0.8vw;}
  	.hidden-xs .evetitre {font-size:1.5vw;line-height:1.5vw;}
  	.hidden-xs .evedate {font-size:1.1vw;}
  	.hidden-xs .evelieu {font-size:0.8vw;}
  	.hidden-xs .evediv p {margin: 0;}

  	.hidden-xs .p-evediv {padding:1.8vw 1.5vw;}
  	.hidden-xs .p-evetype {font-size:1vw;}
  	.hidden-xs .p-eveartiste {font-size:0.9vw;}
  	.hidden-xs .p-evetitre {font-size:1.8vw;line-height:1.8vw;}
  	.hidden-xs .p-evedate {font-size:1.3vw;}
  	.hidden-xs .p-evelieu {font-size:1.1vw;}
  	.hidden-xs .p-evetexte {font-size:1em;}
  	.hidden-xs .p-evediv p {margin: 0;}
  	.hidden-xs .p-evetexte p{margin-top:1vw;}
  	.hidden-xs .p-retour {margin-top:30px;}
  	.hidden-xs .evemask {position:absolute;width:33.33%;top:0;left:0;bottom:0;}

  	.hidden-xs .p-artdiv {padding:1.8vw 1.5vw;}
  	.hidden-xs .p-arttitre {font-size:1.8vw;line-height:1.8vw;}
  	.hidden-xs .p-arttexte {font-size:1em;line-height:1em;padding-top:15px;}


  	.hidden-xs .artmask {position:absolute;width:33.33%;top:0;left:0;bottom:0;background-color:#CCC;}
  	.edito, .items, .footer {font-size:0.9em;}
  	.items div {font-size:0.7em;}
  }
  @media (min-width: 768px) {
  	.header{padding:15px;}
  	.diap {padding:15px 0;}
  	.logo {position:relative;height:200px;}
  	.header {position:relative;height:200px;}
  	div.menu {text-align:right;height:200px;position:relative;}
  	div.menu.medias{height:4em;}
  	div.menu div {position:absolute;bottom:50px;right:0;}
  	div.menu.medias div {bottom:0px;right:15px;}
  	div.logo div {position:absolute;bottom:50px;left:0;width:100%;}
  	.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  		right: -52px;
  	}
  	.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  	  left: -52px;
  	}
  	.swiper-button-next.evt, .swiper-container-rtl .swiper-button-prev.evt {
  		right: 10px;
  	}
  	.swiper-button-prev.evt, .swiper-container-rtl .swiper-button-next.evt {
  	  left: 10px;
  	}
  	.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
  		background-image: url(components/assets/droite.svg);
  	}
  	.swiper-button-prev, .swiper-container-rtl .swiper-button-next {
  		background-image: url(components/assets/gauche.svg);
  	}
  	.edito, .items, .footer {font-size:1em;}
  	.items div {font-size:0.9em;}
  }
  span.audio {font-size:0.9em;}
  .activite {
  	background-color:#666;
  	padding:15px;
  	text-align:center;
  	text-transform:uppercase;
  	color:#FFF;
  }
  .activite-header h3 {
  	text-transform:uppercase;
  	color:#fff;
  }
  .activite-header {
  	color:#fff;
  }
  .clearfix {
  	float:left;
  	clear:both;
  	width:100%;
  }
  .cursor {
  	cursor:pointer;
  }
  .annee {
  	color:#FFF;
  }
  .evt-vignette {
  	transition: opacity 1s;
  }
  .img-container {
      padding-left:2px;
      margin-left:-2px;
  }

  .widget-cloudcast-image {
  	display:none;
  }
  .main-player .pl {
  	opacity:0;
      transition: opacity 1s;
      display:none;
  }
  .main-player .pl.ready {
      display:block;
  	opacity:0;
  }
  .main-player .pl.ok {
      display:block;
  	opacity:1;
  }
  .activite-menu {
      padding:30px 30px 5px 30px;
      background:#d9d9d9;
      text-align:center;
      height:4em;
  }
  .activite-content {
      padding:30px 15px;
      background:#d9d9d9;
  }
  .activite-menu a {
      color:#AAA;
      text-transform:uppercase;
      transition: color 1s;
  }
  .main-player {
      background:#FFF;
  }
  .sontitre {
      font-size:1em;
  }
  .sonartiste {
      font-size:0.8em;
      line-height:1em;
  }
  a.timeline {
      color:#EEE;
      padding:8px 8px 5px 8px;
      border:2px solid #0000;
  }
  a.timeline.active {
      color:#FFF;
      border:2px solid #FFF;
  }
  a.timeline:hover {
      color:#DDD;
  }
  .evt-vignette.activites {
      margin-bottom:30px;
  }
  #media .swiper-slide-prev .activite-menu {
      text-align: left;
  }
  #media .swiper-slide-next .activite-menu {
      text-align: right;
  }
  #media .swiper-slide-prev .activite-menu a{
      position:absolute;
      width:200px;
      right:-200px;
      padding:0 15px;
      z-index:1000;
  }
  #media .swiper-slide-next .activite-menu a{
      position:absolute;
      width:200px;
      padding:0 15px;
      left:-200px;
  }
  #mediamenu {
      height:4em;
      width:40%;
  }
  #mediamenu.swiper-container {
      overflow:visible;
  }
  .mediamenu-container {
      width:100%;
      overflow:hidden;
  }
  .swiper-slide-active .activite-menu a {
      color:#000;
  }
  .mix-button {
     margin-bottom:30px;
     cursor:pointer;
     height:220px;
  }
  .mix-button img {
     filter: grayscale(100%);
      transition: filter 1s;
  }
  .mix-button.selected img {
      filter: sepia(100%) hue-rotate(20deg);
  }
  iframe {
      display:block;
  }
  .diap-credits {
      position:absolute;
      width:100%;
      bottom:0;
      left:0;
      background:#0009;
      text-align:center;
      padding-top:5px;
      font-size:0.8em;
      color:#EEE;
  }
  .mini_capsule-video {
      padding-top:0;
  }
  .videotrigger {
      cursor:pointer;
  }
  .video-title {
      padding-top:5px;
      font-size:0.8em;
      line-height:1.1em;
      color:#999;
  }

  .swiper-button-prev.vid {
  	left: auto;
  	right: 15px;
  	bottom: -40px;
  	top: auto;
  	background-image: url(components/assets/droite.svg);
  }
  .swiper-button-next.vid {
  	left: 15px;
  	bottom: -40px;
  	top: auto;
  	background-image: url(components/assets/gauche.svg);
  }
  .swiper-slide {
      overflow:hidden;
  }
  .disque h3 {
      color:#FFF;
  }
  .disque .descriptif {
      color:#FFF;
  }
  .disque .chapo {
      color:#999;
  }
  .video-responsive {
  overflow:hidden;
  padding-bottom:56.25%;
  position:relative;
  height:0;
  }

  .video-responsive iframe {
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
  }
  span.archives {
    color:#FFF;
    cursor:pointer;
    position:relative;
  }
  .annees-archives {
    display:none;
    position:absolute;
    position: absolute;
    background-color: #000;
    top: 100%;
    z-index: 10;
    right: 0;
    padding: 10px;
  }
  .annees-archives a.timeline {
    line-height:2;
  }
}
