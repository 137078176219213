.chapitre {
  .swiper {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .slide {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
