.presentation {
  .vignette-img {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    mix-blend-mode:multiply;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
